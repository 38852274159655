import React from "react"
import "./index.scss"
import { onSuccessTYP } from "../UI/PopUp/index"
import Main from "../sections/Main"
import { Helmet } from "react-helmet"

const OkPage = () => {
  const redirectToMain = () => {
    window.location.href = "https://refinance.tascombank.ua"
    return false
  }

  React.useEffect(() => {
    onSuccessTYP(redirectToMain)
  }, [])

  return (
    <div class="content">
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Main isThanks={true} />
    </div>
  )
}

export default OkPage
